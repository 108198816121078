import { useCallback, useEffect, useMemo, useState } from 'react'
import { useElementSize, useMediaQuery } from '@mantine/hooks'
import { ClientLogo } from 'common/components'
import { useMessageActions, useSelector } from 'common/hooks'
import { isNil } from 'lodash'
import { ROUTES } from 'services'
import { UserMenu } from 'user/components'

import Menu from './Menu'

const ACTION_ICON_BUTTON_WIDTH = 45
const BASIC_GRID_GAP = 8

export default function HeaderWrapper() {
  const { menu, logo } = useSelector((state) => state.iframe)
  const { sendApprovalBackToHomeFromDetailsMessage } = useMessageActions()
  const { ref: logoRef, width: logoWidth } = useElementSize()
  const { ref: navbarWrapperRef, width: navbarWrapperWidth } = useElementSize()
  const { ref: navbarRef } = useElementSize()
  const { ref: userMenuRef, width: userMenuWidth } = useElementSize()

  const [visibleLinkCount, setVisibleLinkCount] = useState<number | null>(null)
  const [linksWidth, setLinksWidth] = useState<number[]>([])
  const [isDropdownMenuVisible, setIsDropdownMenuVisible] = useState(false)

  const leftWidthSpaceForNavbarInHeader = useMemo(
    () =>
      navbarWrapperWidth -
      (logoWidth + userMenuWidth + ACTION_ICON_BUTTON_WIDTH + BASIC_GRID_GAP * 2),
    [navbarWrapperWidth, logoWidth, userMenuWidth],
  )

  const handleResize = useCallback(() => {
    if (navbarRef?.current && menu) {
      let totalWidth = 0
      let count = 0

      for (let i = 0; i < linksWidth.length; i++) {
        totalWidth += linksWidth[i] + BASIC_GRID_GAP
        if (totalWidth > leftWidthSpaceForNavbarInHeader) {
          break
        }
        count++
      }
      setVisibleLinkCount(count)
    }
  }, [linksWidth, menu, navbarRef, leftWidthSpaceForNavbarInHeader])

  const handleRedirectToHomePage = useCallback(
    () => sendApprovalBackToHomeFromDetailsMessage({ to: 'HOME' }),
    [sendApprovalBackToHomeFromDetailsMessage],
  )

  useEffect(() => {
    window.addEventListener('resize', () => {
      handleResize()
      setIsDropdownMenuVisible(false)
    })

    return () => {
      // clearTimeout(timeout)
      window.removeEventListener('resize', () => {
        handleResize()
        setIsDropdownMenuVisible(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResize])

  useEffect(() => {
    if (navbarRef.current && menu) {
      const nav = navbarRef.current?.children[0]?.children as HTMLAnchorElement[]

      if (nav.length === menu.length) {
        setLinksWidth(Array.from(nav).map((item) => item.clientWidth))
      }
    }
  }, [navbarRef, menu])

  useEffect(() => {
    if (menu) {
      handleResize()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, handleResize])

  const isMobileView = useMediaQuery('(max-width: 768px)')

  return (
    <div className={'flex items-center gap-4 h-[60px]'} ref={navbarWrapperRef}>
      <div className={'flex w-full flex-1 gap-4'}>
        <div className={'flex items-center'} ref={logoRef}>
          {logo && (
            <ClientLogo
              classes={{ link: 'h-[20px]' }}
              link={ROUTES.home()}
              logoUrl={logo}
              onClick={handleRedirectToHomePage}
            />
          )}
        </div>
        {!isMobileView && menu && (
          <Menu
            hiddenMenu={menu.slice(
              isNil(visibleLinkCount) ? menu.length : visibleLinkCount,
              menu.length,
            )}
            isDropdownMenuVisible={isDropdownMenuVisible}
            ref={navbarRef}
            setIsDropdownMenuVisible={setIsDropdownMenuVisible}
            visibleMenu={menu.slice(
              0,
              visibleLinkCount === 0 ? 0 : visibleLinkCount || menu.length,
            )}
          />
        )}
      </div>
      <div className={'flex items-center gap-4'} ref={userMenuRef}>
        <UserMenu />
      </div>
    </div>
  )
}
