import { useMemo } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Image } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { AuthLoginStep } from 'auth/models'
import clsx from 'clsx'
import { ClientLogo, XeltoLogo } from 'common/components'
import { useLoadClientAssets, useSelector } from 'common/hooks'
import { loadStaticFromPublic } from 'common/utils'
import { ROUTES } from 'services'

import LoginStepper from './LoginStepper'

import classes from './components.module.css'

export default function AuthWrapper() {
  const { isLoginPageUiCenter, loginStep } = useSelector((state) => state.auth)
  const { logoColor } = useLoadClientAssets()
  const matches = useMediaQuery('(min-width: 768px)')
  const isAccessToken = useMemo(() => localStorage.getItem('access_token'), [])
  const isClientId = useMemo(() => localStorage.getItem('client_id'), [])
  const isApplicationid = useMemo(() => localStorage.getItem('application_id'), [])
  const isSiteId = useMemo(() => localStorage.getItem('site_id'), [])

  const isSelectStep = useMemo(
    () =>
      loginStep !== AuthLoginStep.SIGN_IN &&
      loginStep !== AuthLoginStep.INDEX &&
      loginStep !== AuthLoginStep.FORGOT_PASSWORD &&
      loginStep !== AuthLoginStep.RESET_PASSWORD,
    [loginStep],
  )

  const isForgotOrResetPasswordStep = useMemo(
    () =>
      loginStep === AuthLoginStep.FORGOT_PASSWORD ||
      loginStep === AuthLoginStep.RESET_PASSWORD,
    [loginStep],
  )

  if (isAccessToken && isClientId && isApplicationid && isSiteId) {
    return <Navigate to={ROUTES.home()} />
  }

  if (isSelectStep) {
    return (
      <main
        className={clsx(
          'w-full min-h-screen flex flex-col md:grid md:grid-cols-[minmax(300px,500px),1fr]',
          matches ? '' : classes.loginFormSelectBackground,
        )}
      >
        <section
          className={clsx(
            'z-20 px-6 py-4 w-full h-auto min-h-[auto] flex items-center justify-center md:min-h-full md:h-max md:max-w-[550px]',
            classes.loginFormSelectBackground,
          )}
        >
          <div className={'w-full flex flex-col items-center md:my-auto'}>
            <div className={'w-full flex flex-col items-start md:gap-4 md:max-w-[300px]'}>
              <div className={'hidden md:block md:mb-[70px]'}>
                <XeltoLogo width={201} />
              </div>
            </div>
            <LoginStepper />
          </div>
        </section>
        <section
          className={clsx(
            'z-30 w-full h-full px-6 py-4 flex items-center justify-start bg-white md:max-w-[800px]',
            matches ? '' : 'rounded-tl-[100px]',
          )}
        >
          <Outlet />
        </section>
      </main>
    )
  }

  return (
    <main className={'w-full min-h-screen flex justify-center'}>
      <div
        className={clsx(
          'hidden fixed top-0 left-0 z-10 w-[50%] h-full md:block',
          classes.loginFormBackground,
        )}
      />
      <div className={'flex flex-col h-auto w-full md:flex-row md:w-auto md:my-auto'}>
        <section
          className={clsx(
            'flex justify-end w-full z-20 py-[40px] md:py-4 md:w-[50vw]',
            matches ? '' : `${classes.loginFormBackground} px-8`,
          )}
        >
          <div
            className={
              'flex flex-col text-center w-full md:text-left md:px-8 md:items-center'
            }
          >
            <div className={'flex flex-col w-full max-w-[504px] md:items-start'}>
              <XeltoLogo classes={{ image: 'mx-auto md:ml-0' }} width={150} />
            </div>
            <Image
              className={'hidden max-w-[504px] md:block md:mt-[40px]'}
              radius={40}
              src={loadStaticFromPublic('sign-in-image-2.png')}
            />
          </div>
        </section>
        <section
          className={clsx(
            'flex justify-start w-full z-20 px-8 py-8 md:w-[50vw] md:py-4 md:h-auto',
            isForgotOrResetPasswordStep ? 'px-4 md:px-12' : '',
            isLoginPageUiCenter ? 'md:justify-center' : '',
          )}
        >
          <div
            className={clsx(
              'flex flex-col w-full max-w-[650px]',
              isLoginPageUiCenter ? 'items-center justify-center' : 'items-start',
            )}
          >
            <div className={'hidden w-full md:block'}>
              {logoColor && (
                <div
                  className={clsx(
                    'flex',
                    isLoginPageUiCenter ? 'justify-center' : 'justify-start',
                  )}
                >
                  <ClientLogo height={50} logoUrl={logoColor} />
                </div>
              )}
            </div>
            <Outlet />
          </div>
        </section>
      </div>
    </main>
  )
}
