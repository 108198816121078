import clsx from 'clsx'

interface IProps {
  width?: number
  height?: number
  url?: string
  classes?: {
    link?: string
    image?: string
  }
}

export default function XeltoLogo({ width, height, url, classes }: IProps) {
  if (url) {
    return (
      <a className={clsx('inline-block', classes?.link)} href={url}>
        <img
          alt={'Xelto'}
          className={classes?.image}
          height={height}
          src={'https://www.xelto.com/wp-content/uploads/2020/07/xelto_logotype.png'}
          width={width}
        />
      </a>
    )
  }

  return (
    <img
      alt={'Xelto'}
      className={classes?.image}
      height={height}
      src={'https://www.xelto.com/wp-content/uploads/2020/07/xelto_logotype.png'}
      width={width}
    />
  )
}
